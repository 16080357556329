const colors = {
  info: 'info',
  error: 'error',
  neutral: 'neutral',
  success: 'success',
  warning: 'warning',
  orange: 'orange',
};

export const STAGES = {
  CRAWLING_COMPLETED: colors.info,
  CRAWLING_FAILED: colors.error,
  DOWNLOADING: colors.neutral,
  DOWNLOADING_ERROR: colors.warning,
  DOWNLOADS_COMPLETED: colors.info,
  REPORT_CREATION: colors.neutral,
  REPORT_CREATION_COMPLETED: colors.success,
  REPORT_CREATION_ERROR: colors.error,
  DOWNLOADED_REPORTED: colors.success,
  PARTIALLY_DOWNLOADED_REPORTED: colors.warning,
  STOPPED: colors.orange,
  OVERLAPPED: colors.orange,
};

export const STATUS = {
  RUNNING: colors.warning,
  FAILED: colors.error,
  ERROR: colors.error,
  ERROR_DOWNLOADING: colors.error,
  ERROR_REPORT: colors.error,
  COMPLETED: colors.success,
  ACTIVE: colors.success,
  INACTIVE: colors.neutral,
  SCHEDULED: colors.warning,
  UNROLED: colors.orange,
};
